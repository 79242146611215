import React from 'react'
import { Container, Row } from 'react-bootstrap'
import { MappedImage } from '../Image'
import { mapSubModuleFields, textStyle, backgroundStyle } from '../../utils'
import Slider from 'react-slick'
import parse from 'html-react-parser'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

export const LogoCarousel = ({
  heading,
  headingDivider,
  subHeading,
  description,
  subModules,
  style,
}) => {
  const settings = {
    className: 'slider center',
    accessibility: true,
    dots: false,
    arrows: true,
    // centerMode: true,
    infinite: false,
    slidesToScroll: 4,
    slidesToShow: style?.slidesToShow || 4,
    speed: 500,
    autoplay: false,
    margin: 80,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToScroll: subModules?.length || 6,
          slidesToShow: subModules?.length || 6,
          infinite: true,
          margin: 80,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          margin: 80,
        },
      },
      {
        breakpoint: 512,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  }
  return (
    <Container
      className="px-0 pb-5 carousel-module text-center"
      fluid
      css={backgroundStyle(style?.backgroundColor)}
    >
      <Row className="m-auto text-center carousel-inner">
        <div className="p-lg-3 p-md-0 m-auto my-4 text-center">
          <h2 className="module-heading" css={textStyle(style?.headingColor)}>
            {heading}
          </h2>
          {headingDivider && <hr className="heading-divider" />}
          <div
            className="module-subheading mx-auto"
            css={textStyle(style?.subHeadingColor)}
          >
            {subHeading}
          </div>
          <div className="carousel-desc" css={textStyle(style?.textColor)}>
            {description && description.description}
          </div>
        </div>
        <Row
          className="p-0 m-0 w-100"
          css={textStyle(style?.textColor)}
          aria-label={`logo c`}
        >
          <Slider {...settings}>
            {subModules &&
              subModules.map(item => (
                <div
                  className="carousel-item m-auto d-flex flex-column justify-content-start align-items-center"
                  css={item?.style?.backgroundColor}
                  key={item.heading}
                >
                  <MappedImage image={item.image} />
                  <div className="w-50">
                    <h3 css={textStyle(item?.style?.headingColor)}>
                      {item.heading}
                    </h3>
                    {item.bodyText && (
                      <div
                        className="lc-body-text"
                        css={textStyle(item?.style?.textColor)}
                      >
                        {parse(item.bodyText.bodyText)}
                      </div>
                    )}
                  </div>
                </div>
              ))}
          </Slider>
        </Row>
      </Row>
    </Container>
  )
}

export const mapLogoCarouselProps = props => mapSubModuleFields(props)
